<template>
  <base-selector-autocomplete
    :items="items"
    :initial-value="initialValue"
    item-text="full_name"
    item-value="_id"
    label="Select workflows"
    @select="newSelect => $emit('select', newSelect, 'workflows')"
  />
</template>

<script>
  import BaseSelectorAutocomplete from '@/components/base/selectors/BaseSelectorAutocomplete';
  import { mapGetters } from 'vuex';

  export default {
    name: 'BaseWorkflowsSelector',
    components: {
      BaseSelectorAutocomplete,
    },
    props: {
      initialValue: {
        type: Object,
        default: () => {},
      },
    },
    computed: {
      ...mapGetters({
        workflows: 'workflows/noStatsWorkflows',
      }),
      items () {
        const items = [{
          full_name: 'No workflow',
          _id: '',
        }];
        this.workflows.map(workflow => {
          items.push({
            full_name: `${workflow.name} ${workflow._id}`,
            _id: workflow._id,
          });
        });
        return items;
      },
    },
  };
</script>

<style scoped>

</style>
